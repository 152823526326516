// https://stackoverflow.com/questions/58538327/convert-paths-with-items-to-tree-object

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sortArrayByWeight, sortArrayAlphabetically } from "./helpers";
// import data from "./sampleData";

function stringHasExtension(string) {
  return !!/(?:\.([^.]+))?$/.exec(string)[1];
}

function getPathLength(obj) {
  return obj.path.length;
}

export function formatData(data) {
  return _.sortBy(
    _.map(
      data,
      (obj) => ({
        path: obj.path,
        label: obj.label,
        slug: obj.slug,
        children: [],
      }),
      getPathLength
    )
  );
}

function assignUUID(arr) {
  let assignedArr = arr;
  assignedArr.forEach((obj) =>
    Object.assign(obj, {
      id: uuidv4(),
      ...obj,
    })
  );
  return assignedArr;
}

function sortTree(arr) {
  arr.forEach((node) => {
    if (node.hasOwnProperty("children")) sortTree(node.children);
  });
  return sortArrayByWeight(sortArrayAlphabetically(arr, "label"), "weight");
}

function mergeIndexWithParent(arr) {
  const newArr = JSON.parse(JSON.stringify(arr));

  function removeItem(array, index) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }

  newArr.forEach(function iterate(node) {
    if (Array.isArray(node.children) && node.children.length) {
      const foundIndex = node.children.findIndex(({ path }) => {
        const pathList = path ? path.split("/").reverse() : [];
        return pathList[0] === "index.md";
      });

      if (foundIndex > -1) {
        const foundObj = node.children[foundIndex];
        const children = removeItem(node.children, foundIndex);

        node = Object.assign(node, {
          ...foundObj,
          id: node.id,
          parentId: node.parentId,
          children,
          slug: null, // TEMP
        });
      }

      node.children.forEach(iterate);
    }
  });

  return newArr;
}

function buildTree(arr, debug = false) {
  function createBranch([leaf, ...pathParts], nodeMap, node, branch) {
    if (!nodeMap.has(leaf)) {
      // create node if not in the Map
      const branch = {
        id: uuidv4(),
        parentId: null,
        label: leaf,
        depth: 2,
        children: [],
      };

      nodeMap.set(leaf, branch);

      if (debug) {
        console.group("Create Branch");
        console.log("Path Part:", leaf);
        console.log("Path Parts:", pathParts);
        console.log("Node:", node);
        console.log("Branch:", branch);
        console.groupEnd();
      }

      // if not root of branch create the parent...
      if (pathParts.length) createBranch(pathParts, nodeMap, node, branch);
    }

    // if a parent assign the child to the parent's families
    if (branch) {
      if (debug) {
        console.group("Push Node");
        console.log("Path Part:", leaf);
        console.log("Path Parts:", pathParts);
        console.log("Node:", node);
        console.log("Branch:", branch);
        console.groupEnd();
      }

      const parentNode = nodeMap.get(leaf);
      parentNode.children.push({ ...branch, parentId: parentNode.id });
    }
  }

  const map = arr.reduce((nodeMap, { directory: path, ...node }) => {
    const pathParts = path.split("/").reverse(); // get all nodes in branch and reverse
    let leaf = pathParts[0]; // get the leaf

    if (!leaf) {
      nodeMap.set("root", {
        depth: 0,
        children: [],
        ...node,
      });
    }

    // if the leaf doesn't exist create the entire branch
    if (!nodeMap.has(leaf)) {
      createBranch(pathParts, nodeMap, { direcory: path, ...node });
    }

    // assign the item to the leaf's items
    const parentNode = nodeMap.get(leaf);
    parentNode.children.push({
      parentId: parentNode.id,
      depth: 1,
      ...node,
    });

    return nodeMap;
  }, new Map());

  // get a list of uniqnue roots
  const roots = [...new Set(arr.map(({ path }) => path.split("/")[0]))];

  // get an array of root nodes
  return roots.map((root) =>
    map.get(!stringHasExtension(root) ? root : "root")
  );
}

export function flatToTree({
  data: arr,
  mergeIndex = false,
  noRoot = false,
  debug = false,
}) {
  const ids = assignUUID(arr);

  let tree = buildTree(ids, debug);

  // merge index.md file data with its parent
  if (mergeIndex) {
    tree = mergeIndexWithParent(tree);
  }

  // return children if root directory has a single item
  if (noRoot && tree.length === 1) {
    tree = tree[0].children;
  }

  // sort the array by weight and alphabetically
  sortTree(tree);

  return tree;
}

// export function createTree({ data, noRoot = false }) {
//   const withIDs = assignUUID(data);

//   function createBranch([leaf, ...pathList], nodeMap, node) {
//     if (!nodeMap.has(leaf)) {
//       // create node if not in the Map
//       const node = {
//         label: leaf,
//         children: [],
//       };

//       nodeMap.set(leaf, node);

//       // if not root of branch create the parent...
//       if (pathList.length) createBranch(pathList, nodeMap, node);
//     }

//     // if a parent assign the child to the parent's families
//     if (node) {
//       const parentNode = nodeMap.get(leaf);
//       // console.log("pathList", pathList);
//       // console.log("parentNode", parentNode);
//       // console.log("node", { parent: parentNode.id, ...node });
//       parentNode.children.push({
//         // parentId: parentNode.id,
//         ...node,
//       });
//     }
//   }

//   const tree = withIDs.reduce(
//     (nodeMap, { label, directory: path, ...node }) => {
//       const pathList = path.split("/").reverse(); // get all nodes in branch and reverse
//       let leaf = pathList[0]; // get the leaf

//       if (!leaf) {
//         nodeMap.set("root", {
//           // parentId: null,
//           label,
//           children: [],
//           ...node,
//         });
//       }

//       // if the leaf doesn't exist create the entire branch
//       if (!nodeMap.has(leaf)) {
//         createBranch(pathList, nodeMap);
//       }

//       const parentNode = nodeMap.get(leaf);
//       // console.log("pathList", pathList);
//       // console.log("parentNode", parentNode);
//       // console.log("node", { label, path, ...node });
//       parentNode.children.push({
//         // parentId: parentNode.id,
//         label,
//         path,
//         ...node,
//       }); // assign the item to the leaf's items

//       return nodeMap;
//     },
//     new Map()
//   );

//   // get a list of uniqnue roots
//   const roots = [...new Set(withIDs.map(({ path }) => path.split("/")[0]))];

//   // get an array of root nodes
//   let treeArr = roots.map((root) =>
//     tree.get(!stringHasExtension(root) ? root : "root")
//   );

//   // merge index.md file data with its parent
//   // mergeIndexWithParent(treeArr);

//   if (noRoot && treeArr.length === 1) {
//     // treeArr.map(({children}) => ...children);
//     // withIDs = !isEmpty(withIDs[0].children) ? withIDs[0].children : withIDs;
//     treeArr = treeArr[0].children;
//   }

//   treeArr = sortTree(treeArr);

//   return treeArr;
// }
