import React from "react";
import { isEmpty } from "lodash";
import { Box, Image, Link, Flex, Heading } from "@chakra-ui/react";

const Icon = ({ imageUrl, title, href }) => {
  return (
    <Flex flexDirection="row" h="30px" w="100%" mb={1}>
      <Image src={imageUrl} h="100%" align="center" float="left" mr={2} />
      <Box textAlign="left" lineHeight="1.1" mt="auto" mb="auto">
        <Link href={href} target={"_blank"} rel={"noopener noreferrer"}>
          {title}
        </Link>
      </Box>
    </Flex>
  );
};

const ResourceIcons = (resource) => {
  let resourceIcons = [];

  if (resource?.pdfPath) {
    resourceIcons.push(
      <Icon
        imageUrl={"/icons/pdf-icon.svg"}
        href={resource.pdfPath}
        title={resource.pdfTitle}
      />
    );
  }

  if (resource?.csvPath) {
    resourceIcons.push(
      <Icon
        imageUrl={"/icons/excel-icon.svg"}
        href={resource.csvPath}
        title={resource.csvTitle}
      />
    );
  }

  if (resource?.zipPath) {
    resourceIcons.push(
      <Icon
        imageUrl={"/icons/zip-icon.svg"}
        href={resource.zipPath}
        title={resource.zipTitle}
      />
    );
  }

  return resourceIcons;
};

function ResourceDownload({ resources, resourceMap }) {
  const resourceKey = resources?.resourceKey;

  if (!resourceKey) return null;

  const resourceSource = resourceMap.filter(
    (r) => r.resourceKey === resourceKey
  );

  const resourceIcons = ResourceIcons(resourceSource[0]);

  if (isEmpty(resourceIcons)) return null;

  return (
    <Box mb={8}>
      <Heading as="h4" size="xs" mb={3}>
        Download Resources
      </Heading>
      <Flex flexDirection="column">
        {resourceIcons.map((resourceIcon, index) => (
          <div key={index}>{resourceIcon}</div>
        ))}
      </Flex>
    </Box>
  );
}

export default ResourceDownload;
