import React from "react";
import { isEmpty } from "lodash";
import { Heading, List, ListItem, Box } from "@chakra-ui/react";

function TableOfContents({ data }) {
  const headings = data.filter((d) => d.depth > 1 && d.depth < 5);

  const handleAnchorClickEvent = (e) => {
    e.preventDefault();

    const container = document.querySelector("#sidebar-layout-content");
    const anchorLink = e.target.getAttribute("href");
    const anchor = document.querySelector(anchorLink.replace("'", ""));

    if (container && anchor) {
      container.scrollTo({
        top: anchor.offsetTop - 30,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  if (isEmpty(headings)) return null;

  return (
    <Box mb={8}>
      <Heading as="h4" size="xs" mb={3}>
        Page Contents
      </Heading>
      <List spacing={3} fontSize="13px">
        {headings.map((toc, index) => {
          return (
            <ListItem key={index}>
              <a
                href={toc.slug}
                style={{
                  padding: `${(toc.depth - 2) * 10}px`,
                }}
                onClick={handleAnchorClickEvent}
              >
                {toc.label}
              </a>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default TableOfContents;
