import React from "react";
import { graphql } from "gatsby";
import useUser from "../lib/auth";
// import parse from "html-react-parser";
import { Box, Heading } from "@chakra-ui/react";
import SidebarLayout from "../layouts/SidebarLayout";
import ResourceDownload from "../components/ResourceDownload";
import TreeNav from "../components/TreeNav";
import TableOfContents from "../components/TableOfContents";
import { flatToTree } from "../lib/utils/createTree";
import slugify from "slugify";
// import { isEmpty } from "lodash";
import { sortArrayAlphabetically } from "../lib/utils/helpers";

export const resourceTemplateQuery = graphql`
  query ResourceTemplateQuery($slug: String!) {
    heroBg: file(relativePath: { eq: "hero-bg-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
      extension
      publicURL
    }
    allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "resource" } } }
      sort: { fields: [frontmatter___weight], order: [ASC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            weight
            pdf
            pdfLink
            csv
            csvLink
          }
          parent {
            ... on File {
              name
              relativePath
              relativeDirectory
            }
          }
          fields {
            slug
          }
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        pdf
        pdfLink
        csv
        csvLink
      }
      headings {
        value
        depth
      }
      html
    }
  }
`;

const ResourceTemplate = ({ data }) => {
  const { loading, isAuthenticated } = useUser({ required: true });
  if (loading || !isAuthenticated) return null;

  const { html } = data.markdownRemark;

  return <Box className="content" dangerouslySetInnerHTML={{ __html: html }} />;
};

ResourceTemplate.getLayout = (page, height) => {
  const { frontmatter } = page.props.data?.markdownRemark;

  const resources =
    page.props?.data?.allMarkdownRemark?.edges.map(({ node }) => node) || [];
  const resourceNavItems = resources.map(({ frontmatter, parent, fields }) => {
    const { relativePath, relativeDirectory, name } = parent;
    const directoryArr = relativeDirectory.split("/");
    const depth = directoryArr.indexOf("") !== -1 ? 0 : directoryArr.length;
    return {
      label: frontmatter.title || name,
      slug: frontmatter.slug || fields.slug,
      path: relativePath,
      directory: relativeDirectory,
      depth,
      weight: frontmatter.weight || 0,
    };
  });

  const treeNavItems = flatToTree({
    data: resourceNavItems,
    mergeIndex: true,
    noRoot: true,
  });

  const tableOfContents =
    page.props?.data?.markdownRemark?.headings.map(({ value, depth }) => ({
      label: value,
      slug: `#${slugify(value, { lower: true })}`,
      depth,
    })) || [];

  function findNode(arr, key, value) {
    for (const node of arr) {
      if (node.hasOwnProperty(key) && node[key] === value) return node;
      if (node.children) {
        const child = findNode(node.children, key, value);
        if (child) return child;
      }
    }
  }

  function getNestedNodeObjPath(arr, key, value) {
    let nodes = [];
    const tail = findNode(arr, key, value);

    if (tail) {
      nodes.push(tail);

      let findParentNodes = (arr, key, value) => {
        const found = findNode(arr, key, value);

        if (found) {
          nodes.push(found);

          findParentNodes(arr, key, found.parentId);
        }
      };

      const parentNodes = findParentNodes(arr, "id", tail.parentId);

      if (parentNodes) {
        nodes.push(parentNodes);
      }
    }

    return nodes.reverse();
  }

  const pathname = page.props?.location?.pathname;
  const breadcrumbObjs = getNestedNodeObjPath(treeNavItems, "slug", pathname);
  const breadcrumbs = breadcrumbObjs.map((crumb) => ({
    label: crumb.label,
    pathname: crumb.slug,
  }));

  const pageTitle = "Resources";
  const meta = {
    // description: "",
    // ogTitle: "",
    // ogDescription: "",
    // ogType: "",
  };

  return (
    <SidebarLayout
      {...page.props}
      pageTitle={pageTitle}
      meta={meta}
      backgroundImage={page.props?.data?.heroBg}
      breadcrumbs={[
        {
          pathname: "/resources/",
          label: "Resources",
        },
        ...breadcrumbs,
      ]}
      leftSidebar={
        <TreeNav
          data={sortArrayAlphabetically(treeNavItems, "label")}
          location={page.props.location}
          // compact={900}
        />
      }
      rightSidebar={
        <Box>
          <Heading as="h3" size="sm" mb={8}>
            On This Page
          </Heading>
          <ResourceDownload data={frontmatter} />
          <TableOfContents data={tableOfContents} />
        </Box>
      }
    >
      {page}
    </SidebarLayout>
  );
};

export default ResourceTemplate;
